import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from '../selectors/selectors';
import Form from './Form';

function Login({ initializing }) {
  if (initializing) return false;
  return (
    <main className="login">
      <div className="login__content">
        <h1 className="login__heading">Sisäänkirjautuminen</h1>
        <Form />
      </div>
    </main>
  );
}

Login.propTypes = {
  initializing: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  initializing: selectors.initializingView(state),
});

export default connect(mapStateToProps)(Login);
