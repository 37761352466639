import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import CoreLayout from '../layouts/CoreLayout';
import Login from '../components/Login';
import { initView } from '../actions/viewActions';
import * as selectors from '../selectors/selectors';
import * as globals from '../constants/globals';

class Index extends Component {
  componentDidMount() {
    const {
      dispatch,
      router,
      router: { pathname },
    } = this.props;
    dispatch(initView(pathname));
    router.prefetch(globals.PATHNAME_DASHBOARD);
  }

  render() {
    const { initializingView } = this.props;
    if (initializingView) return false;
    return (
      <CoreLayout>
        <Head>
          <title>Häävalokuvaajat: Kirjaudu sisään</title>
        </Head>
        <Login />
      </CoreLayout>
    );
  }
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initializingView: PropTypes.bool.isRequired,
  router: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  initializingView: selectors.initializingView(state),
});

export default withRouter(connect(mapStateToProps)(Index));
